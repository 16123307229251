/** 主包 */
import React, { Component } from "react";
import {
  TextStyle,
  Layout,
  Card,
  Modal,
  TextContainer,
  Select,
  RangeSlider,
  TextField,
  Checkbox
} from "@shopify/polaris";

import { Editor, fontSize } from "react-draft-wysiwyg";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  italic,
  bold,
  underline,
  strikethrough,
  color,
  link,
  unlink,
  undo,
  redo,
  emoji,
  Modifier,
  left,
  center,
  right,
  justify,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import storageUtils from "../../../../utils/storageUtils";
import { countryList } from "../../../../api/index";

/** 样式 */
import "./products_edit.less";
import PorductsDeliveryZones from "../products-edit-zones/products-delivery-zones";
import ProductEditDeliverytimeModal from "../product-edit-deliverytime-modal/index";

class ProductEdit extends Component {
  constructor(props) {
    super(props);
    // console.info(props.productChangeData.productContent)

    //富文本配置
    const blocksFromHtml = htmlToDraft(props.productChangeData.productContent);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);

    this.state = {
      is_template_active: false,
      templateId: 1,
      editorStateV: editorState,
      productData: props.productData || [],
      productChangeData: props.productChangeData || {},
      outOfStockMode: props.productChangeData.outOfStockMode || 0,
      pDeliveryTimeMin: props.productChangeData.pDeliveryTimeMin || 0,
      pDeliveryTimeMax: props.productChangeData.pDeliveryTimeMax || 1,
      pTitle: props.productChangeData.pTitle || "",
      isHideEdt: props.productChangeData.isHideEdt || 0,
      pId: props.productChangeData.pId || 0,
      outOfStockContent: props.outOfStockContent,
      isEditor: false,
      country: [],
      isFlushData: props.isFlushData,
      modal: {
        isActive: false,
        cId: 0,
        cDeliveryTimeMin: 0,
        cDeliveryTimeMax: 0,
        type: "country",
      },
    };
  }

  // 内存泄漏标志
  _isMounted = true;

  UNSAFE_componentWillReceiveProps(nextProps) {
    const blocksFromHtml = htmlToDraft(
      nextProps.productChangeData.productContent || ''
    );
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    if (this._isMounted) {
      this.setState({
        productData: nextProps.productData,
        productChangeData: nextProps.productChangeData,
        outOfStockMode: nextProps.productChangeData.outOfStockMode,
        outOfStockContent: nextProps.productChangeData.outOfStockContent,
        pTitle: nextProps.productChangeData.pTitle,
        pId: nextProps.productChangeData.pId,
        isHideEdt: nextProps.productChangeData.isHideEdt,
        pDeliveryTimeMin: nextProps.productChangeData.pDeliveryTimeMin,
        pDeliveryTimeMax: nextProps.productChangeData.pDeliveryTimeMax,
        isFlushData: nextProps.isFlushData,
      });
      if (nextProps.isEditor == false) {
        this.setState({
          editorStateV: editorState,
        });
      }
    }
    this.initData();
  }

  /**
   * 初始化数据
   */
  initData = async () => {
    var user = storageUtils.get("users");
    const headers = {
      token: user.user_token,
      domain: user.shop_domain,
    };
    // 国家列表
    let countryListRes = await countryList(headers);
    if (countryListRes.code === 200) {
      if (this._isMounted) {
        this.setState({ country: countryListRes.data });
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * 激活产品主题选择弹窗
   */
  activeTemplateModal = () => {
    if (this._isMounted) {
      this.setState({ is_template_active: !this.state.is_template_active });
    }
  };

  /**
   * 关闭产品主题选择弹窗
   */
  closeTemplateModel = () => {
    if (this._isMounted) {
      this.setState({ is_template_active: !this.state.is_template_active });
    }
  };

  /**
   * 选择信息模板
   */
  changeTemplateModel = (v) => {
    if (this._isMounted) {
      this.setState({ templateId: v });
    }
  };
  /**
     * cart/checkout 页面是否显示
     */
 checkboxHideCheckout = (v) => {
  if (this._isMounted) {
      this.setState({ isHideEdt: v });
  }

  this.props.updateSaveOrDiscardComponent("is-hide-edt", v);
}

  /**
   * 富文本配置
   * @returns
   */
  //光标后增加内容 start
  // setEditor = (editor) => {
  //   console.info(111)
  //   this.editor = editor;
  // };
  // focusEditor = () => {
  //   if (this.editor) {
  //     this.editor.focusEditor();
  //   }
  // };
  handleOutOfStockCustomContentChange = (v) => {
    if (this._isMounted) {
      this.setState({ outOfStockContent: v });
    }

    this.props.updateSaveOrDiscardComponent("out-of-stock-content", v);
  };

  //富文本编辑输入
  onEditorStateChange = (editorState) => {
    if (this._isMounted) {
      this.setState({ editorStateV: editorState });
    }
    let str = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    let content = str.replace("font-family: Font;", "");
    content = content.replace("\n", "").replaceAll(/<p><\/p>/g,'').replaceAll('<p style="text-align:start;"></p>',"");
    this.props.updateSaveOrDiscardComponent("edit-setting", content);
  };

  handleOutOfStockSelectChange = (v) => {
    if (this._isMounted) {
      this.setState({ outOfStockMode: v });
    }
    this.props.updateSaveOrDiscardComponent("out-of-stock-mode", v);
  };

  /**
   * 处理预投递区间
   *
   * @param {*} v
   * @param {*} i
   */
  handleRangeSliderChange = (v, i) => {
    this.props.updateSaveOrDiscardComponent(
      "product-edit-range",
      v.join(",").split(",")
    );
  };

  /**
   * 更新组件
   *
   * @param {*} modal
   */
  updateComponentByDeliveryZones = (modal) => {
    let isModalData = this.state.modal;
    isModalData.isActive = true;
    isModalData.cId = modal.cId;
    isModalData.cDeliveryTimeMin = modal.cDeliveryTimeMin;
    isModalData.cDeliveryTimeMax = modal.cDeliveryTimeMax;
    if (this._isMounted) {
      this.setState({ isModalData });
    }
  };

  /**
   * 更新组件
   */
  updateComponent = () => {
    let isModalData = this.state.modal;
    isModalData.isActive = false;
    if (this._isMounted) {
      this.setState({ isModalData, isFlushData: true });
    }
  };

  /**
   * 清除子组件弹窗 props 影响
   */
  clearModal = () => {
    let isModalData = this.state.modal;
    isModalData.isActive = false;
    if (this._isMounted) {
      this.setState({ isModalData });
    }
  };
  render() {
    // stock 文本格式
    const outOfStockformats = [
      { label: "None", value: 0 },
      {
        label:
          "Order now, and we will ship your order as soon as it's in stock!",
        value: 1,
      },
      { label: "Expected back into stock within 48 hours", value: 2 },
      { label: "Custom", value: 3 },
    ];
    const pDeliveryTimeMin = parseInt(this.state.pDeliveryTimeMin);
    const pDeliveryTimeMax = parseInt(this.state.pDeliveryTimeMax);
    //时间间隔
    const rangeValue = [pDeliveryTimeMin, pDeliveryTimeMax];
    const min = 0;
    const max = 100;
    const step = 1;
    return (
      <div className="et-edit-content">
        <div className="product-edit-title">
          <TextStyle variation="strong">{this.state.pTitle}</TextStyle>
        </div>
        <div className="product-edit-editor">
          <Layout>
            <Layout.Section>
              <Card
                title="Widget settings"
                actions={
                  [
                    // { content: "Theme", onAction: this.activeTemplateModal },
                  ]
                }
                sectioned
              >
                <div className="editor">
                  <Editor
                    ref={this.setEditor}
                    // editorState={editorState}
                    editorState={this.state.editorStateV}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                      options: [
                        "fontFamily",
                        "fontSize",
                        "inline",
                        "colorPicker",
                        "link",
                        "emoji",
                        "textAlign",
                        "history",
                      ],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: [
                          "bold",
                          "italic",
                          "underline",
                          "strikethrough",
                        ],
                        bold: { icon: bold, className: undefined },
                        italic: { icon: italic, className: undefined },
                        underline: { icon: underline, className: undefined },
                        strikethrough: {
                          icon: strikethrough,
                          className: undefined,
                        },
                      },
                      fontSize: {
                        icon: fontSize,
                        options: [
                          8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72,
                          96,
                        ],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      fontFamily: {
                        options: [
                          "Font",
                          "Arial",
                          "Georgia",
                          "Impact",
                          "Tahoma",
                          "Times New Roman",
                          "Verdana",
                        ],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      colorPicker: {
                        icon: color,
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        colors: [
                          "rgb(97,189,109)",
                          "rgb(26,188,156)",
                          "rgb(84,172,210)",
                          "rgb(44,130,201)",
                          "rgb(147,101,184)",
                          "rgb(71,85,119)",
                          "rgb(204,204,204)",
                          "rgb(65,168,95)",
                          "rgb(0,168,133)",
                          "rgb(61,142,185)",
                          "rgb(41,105,176)",
                          "rgb(85,57,130)",
                          "rgb(40,50,78)",
                          "rgb(0,0,0)",
                          "rgb(247,218,100)",
                          "rgb(251,160,38)",
                          "rgb(235,107,86)",
                          "rgb(226,80,65)",
                          "rgb(163,143,132)",
                          "rgb(239,239,239)",
                          "rgb(255,255,255)",
                          "rgb(250,197,28)",
                          "rgb(243,121,52)",
                          "rgb(209,72,65)",
                          "rgb(184,49,47)",
                          "rgb(124,112,107)",
                          "rgb(209,213,216)",
                        ],
                      },
                      link: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        dropdownClassName: undefined,
                        showOpenOptionOnHover: true,
                        defaultTargetOption: "_self",
                        options: ["link", "unlink"],
                        link: { icon: link, className: undefined },
                        unlink: { icon: unlink, className: undefined },
                        linkCallback: undefined,
                      },
                      emoji: {
                        icon: emoji,
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        emojis: [
                          "😀",
                          "😁",
                          "😂",
                          "😃",
                          "😉",
                          "😋",
                          "😎",
                          "😍",
                          "😗",
                          "🤗",
                          "🤔",
                          "😣",
                          "😫",
                          "😴",
                          "😌",
                          "🤓",
                          "😛",
                          "😜",
                          "😠",
                          "😇",
                          "😷",
                          "😈",
                          "👻",
                          "😺",
                          "😸",
                          "😹",
                          "😻",
                          "😼",
                          "😽",
                          "🙀",
                          "🙈",
                          "🙉",
                          "🙊",
                          "👼",
                          "👮",
                          "🕵",
                          "💂",
                          "👳",
                          "🎅",
                          "👸",
                          "👰",
                          "👲",
                          "🙍",
                          "🙇",
                          "🚶",
                          "🏃",
                          "💃",
                          "⛷",
                          "🏂",
                          "🏌",
                          "🏄",
                          "🚣",
                          "🏊",
                          "⛹",
                          "🏋",
                          "🚴",
                          "👫",
                          "💪",
                          "👈",
                          "👉",
                          "👉",
                          "👆",
                          "🖕",
                          "👇",
                          "🖖",
                          "🤘",
                          "🖐",
                          "👌",
                          "👍",
                          "👎",
                          "✊",
                          "👊",
                          "👏",
                          "🙌",
                          "🙏",
                          "🐵",
                          "🐶",
                          "🐇",
                          "🐥",
                          "🐸",
                          "🐌",
                          "🐛",
                          "🐜",
                          "🐝",
                          "🍉",
                          "🍄",
                          "🍔",
                          "🍤",
                          "🍨",
                          "🍪",
                          "🎂",
                          "🍰",
                          "🍾",
                          "🍷",
                          "🍸",
                          "🍺",
                          "🌍",
                          "🚑",
                          "⏰",
                          "🌙",
                          "🌝",
                          "🌞",
                          "⭐",
                          "🌟",
                          "🌠",
                          "🌨",
                          "🌩",
                          "⛄",
                          "🔥",
                          "🎄",
                          "🎈",
                          "🎉",
                          "🎊",
                          "🎁",
                          "🎗",
                          "🏀",
                          "🏈",
                          "🎲",
                          "🔇",
                          "🔈",
                          "📣",
                          "🔔",
                          "🎵",
                          "🎷",
                          "💰",
                          "🖊",
                          "📅",
                          "✅",
                          "❎",
                          "💯",
                          "💸",
                          "♻",
                          "🤝",
                          "📱",
                        ],
                      },
                      textAlign: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ["left", "center", "right", "justify"],
                        left: { icon: left, className: undefined },
                        center: { icon: center, className: undefined },
                        right: { icon: right, className: undefined },
                        justify: { icon: justify, className: undefined },
                      },
                      history: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ["undo", "redo"],
                        undo: { icon: undo, className: undefined },
                        redo: { icon: redo, className: undefined },
                      },
                    }}
                  />
                </div>

                <div className="out-of-stock">
                  <div>
                    <h2>Out of stock message</h2>
                  </div>
                  <div className="out-of-stock-select">
                    <Select
                      options={outOfStockformats}
                      onChange={this.handleOutOfStockSelectChange}
                      value={this.state.outOfStockMode}
                    />
                  </div>
                  {this.state.outOfStockMode === 3 ? (
                    <div className="out-of-stock-message">
                      <TextField
                        value={this.state.outOfStockContent}
                        onChange={this.handleOutOfStockCustomContentChange}
                        multiline={1}
                        placeholder="Edit your custom message here..."
                      />
                    </div>
                  ) : null}
                </div>
                <div className="range">
                  <div>
                    <h2>Estimated delivery time</h2>
                  </div>
                  <RangeSlider
                    output
                    // label="Money spent is between"
                    value={rangeValue}
                    min={min}
                    max={max}
                    step={step}
                    onChange={this.handleRangeSliderChange}
                  />
                </div>
                <div>
                  <Checkbox
                    label="Hide ETA message for this product"
                    checked={this.state.isHideEdt}
                    onChange={this.checkboxHideCheckout}
                  />
                </div>
              </Card>
            </Layout.Section>
          </Layout>
          <PorductsDeliveryZones
            updateComponentByDeliveryZones={this.updateComponentByDeliveryZones}
            isFlushData={this.state.isFlushData}
            pId={this.state.pId}
            country={this.state.country}
          ></PorductsDeliveryZones>
          <ProductEditDeliverytimeModal
            clearModal={this.clearModal}
            updateComponent={this.updateComponent}
            pId={this.state.pId}
            modalData={this.state.modal}
          />
        </div>
        <Modal
          open={this.state.is_template_active}
          onClose={this.closeTemplateModel}
          title="Theme"
          primaryAction={{
            content: "Save",
            onAction: () => {},
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: () => {},
            },
          ]}
        >
          <div className="info-template-population">
            <Modal.Section>
              <TextContainer>
                <div
                  className="info-template"
                  style={{
                    backgroundColor:
                      this.state.templateId == 1 ? "#F6F6F7" : "",
                  }}
                  onClick={this.changeTemplateModel.bind(this, 1)}
                >
                  <div className="info-template-sum">
                    <img src="/image/5.png"></img>
                  </div>
                </div>
              </TextContainer>
            </Modal.Section>
            <Modal.Section>
              <TextContainer>
                <div
                  className="info-template"
                  style={{
                    backgroundColor:
                      this.state.templateId == 2 ? "#F6F6F7" : "",
                  }}
                  onClick={this.changeTemplateModel.bind(this, 2)}
                >
                  <div className="info-template-sum">
                    <img src="/image/1.png"></img>
                  </div>
                </div>
              </TextContainer>
            </Modal.Section>
            <Modal.Section>
              <TextContainer>
                <div
                  className="info-template"
                  style={{
                    backgroundColor:
                      this.state.templateId == 3 ? "#F6F6F7" : "",
                  }}
                  onClick={this.changeTemplateModel.bind(this, 3)}
                >
                  <div className="info-template-sum">
                    <img src="/image/4.png"></img>
                  </div>
                </div>
              </TextContainer>
            </Modal.Section>
            <Modal.Section>
              <TextContainer>
                <div
                  className="info-template"
                  style={{
                    backgroundColor:
                      this.state.templateId == 4 ? "#F6F6F7" : "",
                  }}
                  onClick={this.changeTemplateModel.bind(this, 4)}
                >
                  <div className="info-template-sum">
                    <img src="/image/2.png"></img>
                  </div>
                </div>
              </TextContainer>
            </Modal.Section>
            <Modal.Section>
              <TextContainer>
                <div
                  className="info-template"
                  style={{
                    backgroundColor:
                      this.state.templateId == 5 ? "#F6F6F7" : "",
                  }}
                  onClick={this.changeTemplateModel.bind(this, 5)}
                >
                  <div className="info-template-sum">
                    <img src="/image/3.png"></img>
                  </div>
                </div>
              </TextContainer>
            </Modal.Section>
          </div>
        </Modal>
      </div>
    );
  }
}
export default ProductEdit;
